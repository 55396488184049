import React, { useState, useEffect, createElement } from 'react';
import { fetchClient, fetchSeller } from '../services/client-api';
import { disconnect, getItemSession, scrollToTop } from '../utils';

const Account = () => {
    const [client, setClient] = useState([]);
    const [seller, setSeller] = useState([]);

    const dados = JSON.parse(getItemSession('_dados'))
    const idCliente = dados.idCliente;

    useEffect(() => {
        scrollToTop();
    }) 

    // Load Data
    useEffect(() => {
        fetchClient(idCliente)
            .then(result => {
                if (result.data.Data) {
                    setClient(result.data.Data.Dados[0])
                }
            })
            .catch(reject => {
                disconnect();
            })
    }, [idCliente])
    
    useEffect(() => {
        fetchSeller(idCliente)
            .then(result => {
                if (result.data.Data.length) {
                    setSeller(result.data.Data.Dados[0])
                }
            })
        /* Deixado somente 1 disconnect */
    }, [idCliente])

    return (
        <>
            <section className="minha-conta container">
                <div className="minha-conta--wrapper">
                    <div className="sect-header">
                        <div className="sect-titulo">
                            <h2 className="titulo h2">Minha Conta</h2>
                        </div>
                        <hr />
                    </div>
                    <div className="minha-conta--conteudo">
                        <h4 className="titulo h4">{client.RazaoSocial}</h4>
                        <p className="sect-texto">{client.EnderecoCompleto}</p>
                        <h4 className="titulo h4">Meu Consultor</h4>
                        {seller.length >0 &&
                        <>
                        <h5 className="titulo h5">{seller.Nome}</h5>
                        <p className="sect-texto">{seller.Email}</p>
                        <p className="sect-texto">{seller.Telefone}</p>
                        </>
                        }
                       
                       <p className="sect-texto">Sem Consultor</p>
                    </div>
                </div>
                <div className="sect-footer align-items-start">
                    {createElement('a', { href: '/', className: 'btn btn--cinza btn--block btn-full btn--bold' }, "Retornar")}
                </div>
            </section>
        </>
    )
}

export default Account;