import React, { useEffect, createElement } from 'react';
import { setItemSession } from '../utils';

const NotFound = () => {
    

    return (
        <>
            <section className="conteudo_interno container">
                <div>
                    <h1 className="display-4">Oops!<br />Página não encontrada</h1>
                    {createElement('a', { href: "/", className: "mt-5 btn btn--cinza btn--block btn--full btn--bold" }, "Ir para a página inicial")}
                </div>
            </section>
        </>
    )
}

export default NotFound;