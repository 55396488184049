import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

 
export const fetchCart = (ukPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "Pedido",
        "Parametros": [
            {
                "Nome": "ukPedido",
                "Valor": ukPedido
            },
            {
                "Nome": "ApenasRegistrosPedido",
                "Valor": true
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const fetchCartItems = (tokenLogin) => {
    const config = {
        headers: { Authorization: `Bearer ${tokenLogin ?? HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "PedidoItem",
        "Parametros": [
            {
                "Nome": "ApenasRegistrosPedido",
                "Valor": true
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const addToCart = (idProduto, quantity, origin) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "idProduto": parseInt(idProduto),
        "Quantidade": parseInt(quantity),
        "QuantidadeBonificada": 0,
        "Origem": origin
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/AdicionarItem`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const updateCart = (idProduto, item, quantity) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "idProduto": parseInt(idProduto),
        "Item": parseInt(item),
        "Quantidade": parseInt(quantity),
        "QuantidadeBonificada": 0
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/AtualizarItem`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const removeFromCart = (idProduto, item) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "idProduto": parseInt(idProduto),
        "Item": parseInt(item)
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/RemoverItem`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const fetchListItem = (codigoLista) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {

        "Esquema": "web",
        "Tabela": "ListaItem",
        "Parametros": [
            {
                "Nome": "CodigoLista",
                "Valor": codigoLista
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}
 
export const postSelectedListItem = (idListaItem) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "idListaItem": parseInt(idListaItem)
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/AtualizarListaItem`, options, config)
        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const fetchPaymentTerms = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "CondicaoPagamento"
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const postSelectedPaymentTerm = (idCondicaoPagamento) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "idCondicaoPagamento": parseInt(idCondicaoPagamento)
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/AtualizarCondicaoPagamento`, options, config)
        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}
 
export const postSelectedEndereco = (idEndereco) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "idEndereco": parseInt(idEndereco)
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/AtualizarEnderecoEntrega`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const postEndCart = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/FinalizarCarrinho`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const postDuplicateCart = (idPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        
        "Esquema" : "web",
        "Tabela": "HistoricoPedidoItem",
        "Parametros": [
            {
                "Nome": "idPedido",
                "Valor": parseInt(idPedido)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/CopiarPedido`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const resetCart = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {}

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/AbandonarCarrinho`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

 
export const postSurveyRate = (pedido, rating, obs) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Parametros": [
            {
                "Nome": "Nota",
                "Valor": parseInt(rating)
            },
            {
                "Nome": "Observacoes",
                "Valor": obs
            },
            {
                "Nome": "ukPedido",
                "Valor": pedido
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/RegistrarPesquisaSatisfacao`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const pedidoEmProcessamento = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/PedidoEmProcessamento`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const uploadItemLote = (lote, NomePlanilha) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
        "VendaLote": lote,
        "NomePlanilha": NomePlanilha
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Venda/UploadItemLote`, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}