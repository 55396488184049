import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getAllCategories } from '../../containers/CategoriesContainer';

import { NavbarContainer, Menu, MenuItem, SubMenu, SubMenuItem } from './styles';

const NewNavbar = ({ menus }) => {
  const [allCategories, setAllCategories] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);

  const containerRef = useRef(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMenuItemClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  useEffect(() => {
    setAllCategories(getAllCategories());
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (menuId) => {
    setActiveMenu(menuId);
    const submenu = document.getElementById(`submenu-${menuId}`);
    if (submenu) {
      submenu.style.display = 'block';
    }
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
    hideSubmenus();
  };

  const hideSubmenus = () => {
    const submenus = document.querySelectorAll('.submenu');
    submenus.forEach(submenu => {
      submenu.style.display = 'none';
    });
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      hideSubmenus();
      setActiveMenu(null);
    }
  };

  const categoriasPai = allCategories.filter(category => category.idClassificacaoNivelAnterior === null);

  const categoriasFilhas = allCategories.filter(category => category.idClassificacaoNivelAnterior !== null);

  const estruturaHierarquica = categoriasPai.map(pai => {
    return {
      name: {
        id: pai.idClassificacaoNivel,
        descricao: pai.Descricao,
      },
      submenus: categoriasFilhas.filter(filha => filha.idClassificacaoNivelAnterior === pai.idClassificacaoNivel),
    };
  });

  return (
    <NavbarContainer ref={containerRef}>
      <Menu>
        <a role="button" href="/destaque/promocao" className="nav-link px-0 pr-4 justify-content-center menu-name">
          Promoções
        </a>
      </Menu>
      {estruturaHierarquica.map((menu, index) => (
        <Menu
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <MenuItem onClick={handleMenuItemClick}>{menu.name.descricao}</MenuItem>
          {menu.submenus && (
            <SubMenu isOpen={isSubMenuOpen} id={`submenu-${index}`} className="submenu">
              {menu.submenus.map((submenu, subIndex) => (
                <SubMenuItem key={subIndex}>
                  <a href={`/busca?c=${submenu.idClassificacaoNivelAnterior}&s=${submenu.idClassificacaoNivel}`} 
                    className="nav_sub-menu_link" onClick={() => { localStorage.removeItem('MyPageNumber') }}>
                    {submenu.Descricao}
                  </a>
                </SubMenuItem>
              ))}
              <SubMenuItem>
                <a  href={`/busca?c=${menu.name.id}`} className="nav_sub-menu_link" onClick={() => { localStorage.removeItem('MyPageNumber') }}>
                  Ver Todos
                </a>
              </SubMenuItem>
            </SubMenu>
          )}
        </Menu>
      ))}
    </NavbarContainer>
  );
};

export default NewNavbar;
