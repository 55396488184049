import React, { useState, createElement, useEffect } from 'react';
import { formatMoney } from '../../../utils';
import { Link } from 'react-router-dom';

const Cart = ({ items, amount }) => {

  return (
    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="CartDropdown">
      {items?.length > 0 && (
        <>
          <div className="dropdown-menu_cont">
            <ul className="list-unstyled mb-0">
              {items.map((item, index) => (
                <li key={`cart-dropdown-item-${index}`} className="dropdown_cart_item">
                  {createElement('a', { href: `/produto/${item.idProduto}`, className: 'dropdown-menu_link' }, item.Descricao)}
                  <div className="dropdown_cart_item_footer">
                    <p>Quantidade: {item.Quantidade}</p>
                    <p>{item.ValorTotalFinal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(".", ",")}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown-menu_footer">
            <div className="total">
              <p>
                Subtotal: 
                <b>{amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(".", ",")}</b>
              </p>
            </div>
            <div className="dropdown-menu_footer">
             <Link className="btn btn--azul btn--block btn--full btn--bold" to="/carrinho">Fechar pedido</Link>
            </div>
          </div>
        </>
      )}
      {items?.length === 0 && (
        <div className="dropdown-menu_cont text-center">Carrinho vazio</div>
      )}
    </div>
  );
};

export default Cart;
