import React, { useState, useEffect } from 'react';
import Banner from '../components/Home/Banner';
import Promotion from '../components/Home/Promotion';
import TipBar from '../components/TipBar/TipBar';
import Release from '../components/Home/Release';
import Brands from '../components/Home/Brands';
import Modal from "react-bootstrap/Modal";
import { disconnect, setItemSession, getItemSession, scrollToTop } from '../utils';
import { resetCart } from '../services/cart-api';
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { fetchProductsByTagHome } from '../services/products-api';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga'
import { fetchManufacturers } from '../services/manufacturers-api';
import { fetchCategories, fetchCategoriesById } from '../services/categories-api';
import { fetchVersion, fetchHomolog } from '../services/system-api';
import { fetchCartItems } from '../services/cart-api';
import { fetchMudarFilialToken } from '../services/client-api';
import { FaWindowClose } from 'react-icons/fa';

const Home = ({ refreshCartFn }) => {
    const [cookies] = useCookies(["user"]);
    const [, setCookie] = useCookies([]);

    
    useEffect(() => { scrollToTop(); })

    useEffect(() => {
        if (cookies._filialSelecionadaCanopus) {
            let filialSelecionada = (cookies._filialSelecionadaCanopus).toString();
            if (filialSelecionada) {
                loadData(filialSelecionada)
            }
        }

        localStorage.removeItem('visibleBrands');


    }, [cookies._filialSelecionadaCanopus])

    if (!getItemSession('_token') || !cookies._filialSelecionadaCanopus) {
        window.location.href = "/login_vitrine"
    } else {
        if (cookies.LinkProduto) {
            var urlDirection = cookies.LinkProduto
            var urlProduto = cookies.LinkProduto.split('/');
            urlProduto = urlProduto.find(element => element === 'produto')
        }
        if (urlProduto === 'produto') {
            window.location.href = urlDirection
        }
    }
    useEffect(() => {
        const get = getItemSession('_dados');
        const dados = JSON.parse(get);
        if (dados.ApenasVisualizacao) {
            if (cookies.LinkOld) {
                window.location.href = '/logout'
            }
        }
    }, [])

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenFull, setIsOpenFull] = useState(false);
    const [promotions, setPromotions] = useState([]);
    const [releases, setReleases] = useState([]);

    useEffect(() => { ReactGA.pageview(window.location.pathname + window.location.search) },
        [promotions, releases, releases])


    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setReadMessage();
        setIsOpen(false);
    };

    const handleClearCart = () => {
        setIsOpenFull(true);
        setIsOpen(false);

        resetCart()
            .then(result => {
                setDados(result.data.Data);

                const newStorage = {
                    "QuantidadeRegistrosTotal": 0,
                    "QuantidadeRegistrosRetornados": 0,
                    "Paginas": 1,
                    "ResultadosPorPagina": 0,
                    "Dados": []
                }
                setItemSession('_carrinho', JSON.stringify(newStorage))
                refreshCartFn(newStorage);

                setReadMessage();
                setIsOpenFull(false);
            })
            .catch(reject => {
                disconnect();
            })
    }

    useEffect(() => {
        const getNumeroItens = () => {
            const get = getItemSession('_dados');
            const dados = JSON.parse(get);
            if (!dados.ApenasVisualizacao) {
                if (cookies.LinkOld) {
                    var OldLink = cookies.LinkOld
                    Cookies.remove('LinkOld');
                    window.location.href = OldLink
                }
            }
            return dados.NumeroItens;
        }

        const readMessage = getItemSession('_home_message');
        const numeroItens = getNumeroItens();

        if (numeroItens > 0 && readMessage !== "true") {
            showModal()
        }
    }, [])

    useEffect(() => {
        fetchProductsByTagHome('Promocao', 6)
        .then(result1 => {
          if (result1.data.Codigo === 200 && result1.data.Data) {
            const promotions1 = result1.data.Data.Dados ?? [];
      
            fetchProductsByTagHome('VITRINE2', 6)
              .then(result2 => {
                if (result2.data.Codigo === 200 && result2.data.Data) {
                  const promotions2 = result2.data.Data.Dados ?? [];
      
                  // Usando um Set para controlar os IDs já adicionados
                  const uniqueIds = new Set();
      
                  // Adiciona promoções da primeira lista
                  const uniquePromotions1 = promotions1.filter(promo => {
                    if (!uniqueIds.has(promo.idProduto)) {
                      uniqueIds.add(promo.idProduto);
                      return true;
                    }
                    return false;
                  });
      
                  // Adiciona promoções da segunda lista
                  const uniquePromotions2 = promotions2.filter(promo => {
                    if (!uniqueIds.has(promo.idProduto)) {
                      uniqueIds.add(promo.idProduto);
                      return true;
                    }
                    return false;
                  });
      
                  setPromotions([...uniquePromotions1, ...uniquePromotions2]);
                }
              });
            }
        });
      
      
        fetchProductsByTagHome('VITRINE1', 6)
            .then(result => {
                if (result.data.Data) {
                    setReleases(result.data.Data.Dados ?? [])
                    console.log(result.data.Data.Dados, 'rel')
                }
            })       
    }, [])



    const setDados = (novoUkPedido) => {
        setItemSession('_pedido', novoUkPedido);
    }

    const setReadMessage = () => {
        setItemSession('_home_message', true);
    }

    const loadData = (idFilial) => {
        var token = getItemSession('_token')
        fetchMudarFilialToken(idFilial, token).then(r => {
            setItemSession('_filialSelecionadaCanopus', idFilial);
            Promise.all([
                fetchManufacturers(token),
                fetchCategories(token),
                fetchVersion(token),
                fetchHomolog(token),
                fetchCartItems(token),
                fetchCategoriesById(token, 5, false, true)
            ])
                .then(resultFetch => {
                    setItemSession('_fornecedores', JSON.stringify(resultFetch[0].data.Data.Dados));
                    setItemSession('_categorias', JSON.stringify(resultFetch[1].data.Data.Dados))
                    setItemSession('blink_versao', JSON.stringify(resultFetch[2].data.Data))
                    setItemSession('blink_homolog', JSON.stringify(resultFetch[3].data.Data))
                    setItemSession('_carrinho', JSON.stringify(resultFetch[4].data.Data))
                    setItemSession('_marcas', JSON.stringify(resultFetch[5].data.Data.Dados))

                    setCookie('_filialSelecionadaCanopus', idFilial, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });
                })
        })
    }

    return (
        <>
            <Banner />
            <TipBar />
            <Brands />
            <section className="conteudo_interno container">
                <div className="cards-container">
                    <Release destaque={releases}  />

                </div>
            </section>
            <section className="conteudo_interno container">
                <div className="cards-container">
                    <Promotion destaque={promotions} />  
                </div>
            </section>

            {/* <!-- Modal --> */}
            <Modal show={isOpen} className="modal modal-boleto fade">
                <Modal.Header>
                    <div className="modal-header_container">
                        <h2 className="titulo h2">Seu carrinho está com produto(s)!</h2>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { }}>
                        <FaWindowClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Deseja mantê-lo(s) e continuar adicionando itens?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn-primary btn-sm btn-modal-home" onClick={hideModal}>Sim, manter</button>{' '}
                    <button className="btn-warning btn-sm btn-modal-home" onClick={handleClearCart}>Não, esvaziar</button>
                </Modal.Footer>
            </Modal>

            <Modal show={isOpenFull} className="modal">
                <Modal.Body>
                    <div className="text-center">
                        <h3>Esvaziando carrinho!</h3>
                        <p>Por favor, aguarde...</p>
                        <div>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Home);