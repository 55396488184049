import React, { useState, useEffect } from 'react';
import { getAllBrands } from '../../containers/CategoriesContainer';
import OwlCarousel from 'react-owl-carousel';

const options = {
    loop: true, 
    items: 4, 
    nav: true, 
    autoplay: true, 
    autoplayHoverPause: true,
    navText: ['',''],
    smartSpeed: 1000,
    responsive: {
        0: { items: 1 },
        400: { items: 2 },
        600: { items: 3 },
        700: { items: 5 },
        1000: { items: 6 }
    }
};

const Brands = () => {
    const [allBrands, setAllBrands] = useState([]);

    useEffect(() => {
        const fetchBrands = async () => {
            const brands = await getAllBrands();
            const filteredBrands = brands.filter((element) => element.LinkImagem !== null);
            setAllBrands(filteredBrands);
        };
        fetchBrands();
    }, []);

    return (
        <div className="container d-flex align-items-center flex-wrap justify-content-center">
            {allBrands.length > 0 && (
                <OwlCarousel className="owl-theme" {...options}>
                    {allBrands.map((brand) => (
                        <div style={styles.container} key={brand.idClassificacaoNivel}>
                            <a style={styles.slideLink} href={`/busca?b=${brand.idClassificacaoNivel}`}>
                                <img style={styles.slideImage} alt="" src={brand.LinkImagem} />
                            </a>
                        </div>
                    ))}
                </OwlCarousel>
            )}
        </div>
    );
};

const styles = {
    container: {
        width: '95%',
        padding: '30px',
        boxSizing: 'border-box',
        position: 'relative',
        height: '150px', 
        border: '2px solid #ccc',
        margin: '0 5px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    slideLink: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    slideImage: {
        objectFit: 'contain', 
        maxWidth: '150%',  // Aumenta a largura máxima da imagem
        maxHeight: '150%',  // Aumenta a altura máxima da imagem
        transition: 'transform 0.5s',
    },
};

export default Brands;
