const initialState = {
    items: [], //valor inicial
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REFRESH_CART':
            return {
                ...state,
                items: action.payload
            };      
        default:
            return state;
    }
};

export default cartReducer;
