import styled from 'styled-components';

export const NavbarContainer = styled.div`
  background-color: #052037;
  padding: 0px;
  text-align: center;


  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Menu = styled.div`
    display: inline-block;
  margin-right: 150px;
  position: relative;
  
  

  &:hover {
    opacity: 90%;
    transition: 0.3s;
    transform: scale(1.1);
  }

  @media screen and (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
  }
`;

export const MenuItem = styled.div`
  font-size: 14px;
  color: white;
  font-weight: bold;
  cursor: pointer;

`;

export const SubMenu = styled.ul`
  list-style: none;
  padding: 20px;
  margin: -3px;
  position: absolute;
  display: none;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  border: 3px solid #0f61ac;
  z-index: 999999;
  transition: 0.3s;

  &:hover {
    transform: translateY(10px); 
  }

  @media screen and (max-width: 600px) {
    position: static;
  }
`;

export const SubMenuItem = styled.li`
  font-size: 14px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  width: 188px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: #005D85;
  display: flex;
  align-items: center;
  min-width:-webkit-fill-available;
  margin: 6px;

  &:hover {
    color: #d8d8d8;
    transition: 0.9s;
  }
  
  a {
    width: 100%;
    text-decoration: none;
    color: inherit;
  }
`;
