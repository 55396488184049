import React from "react";
import {
  FaShieldAlt,
  FaTruck,
  FaBarcode,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaFilePdf,
} from "react-icons/fa";
import { getItemSession } from "../../../utils";
import Logo from "./logo_small_91x80.png";
import CatalogoSothis2024 from "./CATALOGOSOTHIS2024.pdf";
import CatalogoHamp2024 from "./Catalogohamp2024.pdf";

import * as styles from "./styles";

const TipBarFooter = () => {
  const filial = JSON.parse(getItemSession("_filialSelecionadaCanopus"));
  const filialGoiania = filial === 3 || filial === "3" ? true : false;

  return (
    <styles.FooterTipBarWrapper>
      <styles.TipBarContainer>
        <styles.TipBarColumn>
          <styles.TipBar>
            <styles.TipBarIcon>
              <FaShieldAlt color="white" />
            </styles.TipBarIcon>
            <styles.TipBarTexts>
              <p>
                <strong>Produtos de Qualidade</strong>
              </p>
              <p>
                Oferecemos produtos de qualidade garantida e marcas de renome
                mundial.
              </p>
            </styles.TipBarTexts>
          </styles.TipBar>
        </styles.TipBarColumn>
        <styles.TipBarColumn>
          <styles.TipBar>
            <styles.TipBarIcon>
              <FaTruck color="white" />
            </styles.TipBarIcon>
            <styles.TipBarTexts>
              <p>
                <strong>Entrega Garantida</strong>
              </p>
              <p>
                A melhor disponibilidade de produtos, entrega rápida e garantida
                do início ao fim.
              </p>
            </styles.TipBarTexts>
          </styles.TipBar>
        </styles.TipBarColumn>
        <styles.TipBarColumn>
          <styles.TipBar>
            <styles.TipBarIcon>
              <FaBarcode color="white" />
            </styles.TipBarIcon>
            <styles.TipBarTexts>
              <p>
                <strong>Forma de Pagamento</strong>
              </p>
              <p>Mais comodidade para efetuar seus pagamentos.</p>
            </styles.TipBarTexts>
          </styles.TipBar>
        </styles.TipBarColumn>
        
          <styles.TipBarColumn>
            <styles.TipBar>
              <styles.TipBarIcon>
                <FaFilePdf color="white" />
              </styles.TipBarIcon>
              <styles.TipBarTexts>
                <p>
                  <strong>Download de Catálogos</strong>
                </p>
                
                  {filialGoiania === true ?
                      <p>
                        <a
                        href={CatalogoSothis2024}
                        download="Catalogo_Sothis_2024.pdf"
                        >
                        Sothis (2024)
                        </a>
                      </p>
                      :
                      <p>
                        <a
                        href={CatalogoHamp2024}
                        download="Catalogo_hamp_2024.pdf"
                        >
                        Hamp (2024)
                        </a>
                      </p>
                  }                  
                
              </styles.TipBarTexts>
            </styles.TipBar>
          </styles.TipBarColumn>
      
      </styles.TipBarContainer>
      <styles.Divider />
      {/* Nova Sessão - CONTATO */}
      <styles.TipBarContainerAbout>
        <styles.TipBarColumn>
          <styles.TipBar>
            <styles.TipBarTexts>
              <p>CONTATO</p>
              {filialGoiania === true ? 
                  <p>
                  Av. Perimetral Norte, N°3223 - St. Vila João Vaz, Goiânia - GO,<br/>CEP: 74445-190<br/>
                  Tel: (62) 3414-8400
                  </p>
                  : 
                  <p>
                  Canopus Atacado Moto Peças, Rua 3, 210-A, Jardim Comodoro - Cuiabá - MT <br/>CEP: 78090-593
                  </p>
              }
              
            </styles.TipBarTexts>
          </styles.TipBar>
        </styles.TipBarColumn>
        <styles.TipBarColumn>
          <styles.TipBar>
            <styles.TipBarTextsAbout>
              <h5>SOBRE A EMPRESA</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.grupocanopus.com.br/sobre.html"
              >
                Quem somos
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.grupocanopus.com.br/aviso-de-privacidade.html"
              >
                Política de Privacidade
              </a>
            </styles.TipBarTextsAbout>
          </styles.TipBar>
        </styles.TipBarColumn>
        <styles.TipBarColumn>
          <styles.TipBar>
            <styles.SecuritySection>
              <strong>NOSSAS REDES</strong>
              <ul className="list-unstyled footer__selos">
                <li>
                  <img src={Logo} alt="Selo Canopus Atacado" />
                </li>
              </ul>
              <styles.SocialLinks>
                <ul className="list-unstyled">
                {filialGoiania === true ?
                      <>
                        <li>                  
                          <a
                            className="facebook"
                            href="https://www.facebook.com/profile.php?id=61551432848949"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFacebook />
                          </a>
                        </li>
                        <li>
                          <a
                            className="instagram"
                            href="https://www.instagram.com/siriusatacadodepecas/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaInstagram />
                          </a>
                        </li>                      
                      </>
                    :
                      <>
                        <li>                  
                          <a
                            className="facebook"
                            href="https://www.facebook.com/profile.php?id=61552661655072"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFacebook />
                          </a>
                        </li>
                        <li>
                          <a
                            className="instagram"
                            href="https://instagram.com/atacadocanopus?igshid=OGQ5ZDc2ODk2ZA=="
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaInstagram />
                          </a>
                        </li>
                      </>
                  }
                 
                  
                  <li>
                    <a
                      className="linkedin"
                      href="https://www.linkedin.com/company/grupo-canopus/?originalSubdomain=br"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      className="youtube"
                      href="https://www.youtube.com/@GrupoCanopus"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube />
                    </a>
                  </li>
                </ul>
              </styles.SocialLinks>
            </styles.SecuritySection>
          </styles.TipBar>
        </styles.TipBarColumn>
      </styles.TipBarContainerAbout>
    </styles.FooterTipBarWrapper>
  );
};

export default TipBarFooter;
