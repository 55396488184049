import React, { useState, useEffect } from 'react';
import MenuPrincipal from './Menu/MenuPrincipal';
import { connect } from 'react-redux';
import { getItemSession } from '../../utils';
import CartResponsive from '../NavBarResponsive/Cart/CartResponsive';

const NavBarResponsive = ({ items }) => {
    const [nome, setNome] = useState([]);
    const dados = JSON.parse(getItemSession('_dados'));
    // Load Data
    useEffect(() => {
        if (dados) {
            setNome(dados.Nome);
        }
        if(dados.Nome === "SemLogin") {
            setNome ("Visitante");
        }
    }, [dados, items])
   
    return (
        <>
            <div className="header_nav nav--mobile d-lg-none">
                <button className="btn btn--roxo-escuro divisaomenu" type="button">
                    <span className="dropdown_user_img">
                        <span className="fas fa-user"></span>
                    </span>
                    <span className="btn-user_msg">Olá {nome} </span>
                    <span className="icon fecharmenu btn-close close closemenu">&times;</span>
                </button>
                <div className="header_nav_holder">
                    <MenuPrincipal />
                    {!dados.ApenasVisualizacao &&                 
                        <CartResponsive items={items} />
                    }      
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({   
    items: state.cart.items
})


export default connect(mapStateToProps)(NavBarResponsive);