import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { disconnect, setItemSession, getItemSession } from '../utils';
import {
    fetchProductsById,
    fetchProductsBySuggestion,
    fetchBanners
} from '../services/products-api';
import { Loader } from '../components/Loader/Loader';
import ProductCard from '../components/Product/ProductCard';
import { arrOrigin } from '../constants';
import { addToCart, fetchCartItems, updateCart } from '../services/cart-api';
import * as CartActions from '../store/actions/cart';
import Slider from "react-slick";
import { connect } from 'react-redux';
import Magnifier from "react-magnifier";
import Cookies from 'js-cookie';
import ReactGA from 'react-ga'

const Product = ({ match, refreshCartFn }) => {

    Cookies.remove('LinkProduto');
    useEffect(() => {ReactGA.pageview(window.location.pathname + window.location.search)},[])
    const [isLoading, setLoading] = useState(true);
    const [isLoadingSuggestion, setLoadingSuggestion] = useState(true);
    const [product, setProduct] = useState('');
    const [productsSuggestion, setProdutosSuggestion] = useState([]);
    const [adding, setAdding] = useState(false);
    const [banners, setBanners] = useState([]);
   
    // Props
    const idProduto = match.params.id;
    const history = useHistory();
    const login = JSON.parse(getItemSession('_dados'));

    const [quantity, setQuantity] = useState(1)

    const decrement = () => setQuantity(q => isNaN(q) || q < 2 ? 1 : q - 1);
    const increment = () => setQuantity(q => q >= 0 ? q + 1 : 1);

    const handleChange = (e) => {
        if (parseInt(e.target.value) > 0) {
            setQuantity(parseInt(e.target.value));
        } else {
            setQuantity();
        }
    }
    
    

    useEffect(() => {
        fetchProductsById(idProduto)
            .then(result => {
                setProduct(result.data.Data.Dados[0])
            })
            .then(result => setLoading(false))
            .catch(reject => {
                disconnect();
            })
    }, [idProduto])

    useEffect(() => {
        fetchProductsBySuggestion(4)
            .then(result => {
                setProdutosSuggestion(result.data.Data.Dados)
            })
            .then(result => setLoadingSuggestion(false))
            .catch(reject => {
                disconnect();
            })
    }, [])

    //Carregamendo do banner do produto
    useEffect(() => {
        fetchBanners(idProduto)
            .then(result => {
                setBanners(result.data.Data.Dados)
            })
            .catch(reject => {
               disconnect();
            })
    }, [idProduto])

    const HaveLink = ({ banner }) => {
        if (banner.Sequencia > 0) {
            return (
                <Magnifier 
                    src={banner.Link} 
                    width={300} 
                    mgWidth={180} 
                    mgHeight={180} 
                    zoomFactor={1}
                    />
            )
        }
             
        return <img src={banner.Link} alt="" style={{ margin: 'auto' }} />
    }
    
    function customPaging(i) {
        return <span><img src={banners[i].Link} alt="" style={{ margin: 'auto', width:'100px', height:'100' }} /></span>;
      }
      
  
    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        pauseOnHover:true,
        customPaging:customPaging,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        loop: true,
        arrows: false,
        prevArrow:false,
        nextArrow: false
    };
   
    const handleAddToCart = (e) => {
        e.preventDefault();
        document.getElementById("botaoRoxoPrincipal").setAttribute("disabled","disabled");;
        const intQuantity = parseInt(quantity);

        if (isNaN(intQuantity) || intQuantity < 1) {
            alert("Inserta una cantidad valida para el producto.");
            return false;
        }

        setAdding(true);

        buttonActionDisabled(e);

        // filtro para ver se produto já está no carrinho
        const get = getItemSession('_carrinho');
        const cartItems = JSON.parse(get);

        const item = cartItems.Dados.filter(item => { return item.idProduto === product.idProduto })

        let time = 2000;

        if (item.length > 0) {
            const newQuantity = intQuantity + parseInt(item[0].Quantidade)
           
            updateCart(product.idProduto, item[0].Item, newQuantity)
                .then(resultUpdate => {
                    if (resultUpdate.data.Codigo === 500) {
                        alert(resultUpdate.data.Status);
						setAdding(false);
                        time = 0;
                    } else {
                        fetchCartItems()
                            .then(result => {
                                if (result.data.Data.Dados.length > 0) {
                                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                    refreshCartFn(result.data.Data)
                                }   
								setAdding(false);
                            })
                            .catch(reject => {
                                disconnect();
                            })

                    }
                })
                .catch(reject => {
                    disconnect();
                })

        } else {
            addToCart(product.idProduto, intQuantity, arrOrigin['pagina_produto'])
                .then(resultAdd => {
                    if (resultAdd.data.Codigo === 500) {
                        if (resultAdd.data.CodigoInterno === 4) {
                            disconnect()
                        } else {
                            alert(resultAdd.data.Status);
							setAdding(false);
                            time = 0;
                        }
                    } else {
                        fetchCartItems()
                            .then(result => {
                                if (result.data.Data.Dados.length > 0) {
                                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                    refreshCartFn(result.data.Data)                                    
                                }
								setAdding(false);
                            })
                            .catch(reject => {
                                disconnect();
                            })
                    }
                })
                .catch(reject => {
                    disconnect();
                })
        }


        buttonActionEnabled(e);
    }

    const buttonActionDisabled = (e) => {
        e.target.disabled = true;
    }
    const buttonActionEnabled = (e) => {
        e.target.disabled = false;
    }

    return (
        <>
            <section className="conteudo_interno conteudo_padrao container produtos-detalhe">
                <div className="produtos-detalhe--wrapper">
                    {!isLoading ? (
                        <>
                            <div className="sect-header">
                                <h4 className="titulo h2">Produto - {product.Descricao}</h4>
                                <hr />
                            </div>
                            
                            <div className="produtos-detalhe--conteudo">                            
                                <div className="carrossel_produtos">
                                   {banners &&
                                    <Slider {...settings}>
                                        {banners.map((banner, index) => (
                                        <div className="carrossel_produtos" key={index}>
                                            <HaveLink banner={banner} />
                                        </div>
                                        ))}
                                    </Slider>
                                    }
                                </div>
                                <div className="produtos-detalhe--body">
                                    <h4 className="titulo h4">{product.Descricao}</h4>
                                    <p className="produtos-detalhe--codigo">Código: {product.CodigoProduto.substring(3)}</p>
                                    <p className="produtos-detalhe--codigo">EAN: {product.EAN}</p>
                                    <p className="produtos-detalhe--codigo">Marca: {product.Marca}</p>
                                    <div className="produtos-detalhe--info">
                                        <div className="misc">
                                            <p>Embalagem</p>
                                            <p>{product.Embalagem}</p>
                                        </div>
                                        <div className="misc">
                                            <p>Unidades por caixa</p>
                                            <p>{product.UnidadesPorCaixa}</p>
                                        </div>
                                    </div>
                                    {product.PossuiPrecoPromocional
                                        ?
                                        <>
                                            <p className="produtos-detalhe--valor--de"><strike> {product.PrecoUnitario.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(".", ",")}</strike> <small>unidade</small></p>
                                            <p className="produtos-detalhe--valor--por"> {product.PrecoPromocionalUnitario.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(".", ",")} <small>unidade</small></p>
                                            <p className="produtos-detalhe--valor-total"><b>Total: de <strike> {product.Preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(".", ",")}</strike> por {product.PrecoPromocional.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(".", ",")}</b></p>
                                        </>
                                        :
                                        <>
                                            {!login.ApenasVisualizacao &&
                                                <>
                                                    <p className="produtos-detalhe--valor">{product.PrecoUnitario.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(".", ",")} <small>unidade</small></p>
                                                    <p className="produtos-detalhe--valor-total"><b>Total {product.Preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(".", ",")}</b></p>
                                                </>
                                            }
                                           
                                            
                                        </>
                                    }
                                    {!login.ApenasVisualizacao ? <div className="produtos-detalhe--estoque">{product.Estoque > 0 ? `${product.Estoque} disponíveis` : <span className="badge badge-black-white">Produto não disponível</span>}</div>: ''}
                                   
                                    <hr />
                                    {product.Estoque > 0 && !login.ApenasVisualizacao &&
                                        <form className="form-adicionar">
                                            <button type="button" name="btn-item" className="btn form-adicionar--btn" onClick={decrement}>-</button>
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={quantity}
                                                className="card-item--counter"
                                                onChange={handleChange}
                                                maxLength={4}
                                            />
                                            <button type="button" name="btn-item" className="btn form-adicionar--btn" onClick={increment}>+</button>
                                            <button
                                                type="button"
                                                onClick={handleAddToCart}
                                                className={`btn btn--sm btn--roxo btn--bold ${adding && 'active'}`}
                                                id="botaoRoxoPrincipal"
                                            >
                                                {adding
                                                    ? <><div className="spinner-border spinner-border-sm text-light"></div> Adicionando</>
                                                    : 'Adicionar'
                                                }
                                            </button>
                                        </form>
                                    }
                                </div>
                            </div>
                        </>
                    ) : (
                            <Loader short="false" />
                        )}

                    {product.LinkImagemDescritivo ?
                        <>
                            <hr/>
                            <h4 className="titulo h2">Descrição</h4>
                            <div className='produto-descritivo banner-container flex row center-center m-auto full-view'>
                            
                            {product.LinkImagemDescritivo ?
                                <img src={product.LinkImagemDescritivo} alt="Imagen no cargada"/>
                                :
                                <img src="https://cdn.blink.com.br/Arquivos/sem-foto.png" alt="Imagen no cargada"/>
                            }
                            </div>
                            <hr/>
                        </>
                        :
                        ''                        
                    }
                   
                    <h4 className="titulo h4 mais-produtos-titulo">Produtos incríveis</h4>
                    <div className="cards-container">
                        <div className="overflow-auto">
                            <div className="cards cards-produto mais-produtos-4-cards cards-produto-width-4">
                                {!isLoadingSuggestion ? (
                                    productsSuggestion.map(product =>
                                        <ProductCard
                                            key={`product-detail-card-${product.idProduto}`}
                                            product={product}
                                            origin={arrOrigin['pagina_produto_incrementar']}
                                        />
                                    )
                                ) : (
                                        <Loader short="false" />
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="sect-footer align-items-start">
                    <button className= "btn btn--cinza btn--block btn-full btn--bold"
                         onClick={() => {history.goBack();}}>Voltar</button>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Product); 