import React, { useState, useEffect, createElement } from 'react';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import Modal from "react-bootstrap/Modal";
import { fetchClient } from '../services/client-api';
import { fetchCart, fetchPaymentTerms, postSelectedPaymentTerm, postEndCart, postSurveyRate, fetchCartItems } from '../services/cart-api';
import { fetchDeliveryDate } from '../services/invoices-api';
import { disconnect, scrollToTop, setItemSession, getItemSession } from '../utils';
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { Loader } from '../components/Loader/Loader';
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

const Checkout = ({ refreshCartFn }) => {
    const [client, setClient] = useState([]);
    const [invoice, setInvoice] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [lastPaymentTerm, setLastPaymentTerm] = useState([]);
    const [confirmScreen, setConfirmScreen] = useState(false);
    const [inputs, setInputs] = useState({ idCondicaoPagamento: '' });
    const [, setDeliveryDate] = useState('');
    const [errors, setErrors] = useState([]);
    const [ratingOldInvoice, setRatingOldInvoice] = useState(null);
    const [rating, setRating] = useState(0);
    const [ratingObs, setRatingObs] = useState(null);
    const [, setCart] = useState([]);
    const [habilitapedido, sethabilitapedido] = useState(false);
    // modal
    const [, setIsOpenFrete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    ReactGA.pageview(window.location.pathname + window.location.search)

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    

    const getDados = getItemSession('_dados');
    const dados = JSON.parse(getDados);

    const pedido = getItemSession('_pedido');

    const history = useHistory();

    useEffect(() => {
        fetchCartItems()
            .then(result => {

                setCart(result.data.Data.Dados)
                const qdtEstoque = result.data.Data.Dados.filter(newarr => newarr.Estoque < newarr.Quantidade);

                if (qdtEstoque.length > 0) {
                    alert("Validar os produtos");
                    history.push("/carrinho");
                }

            })
            .then(result => setLoading(false))
            .catch(reject => {

            })
    }, [history])

    useEffect(() => {
        fetchClient(dados.idCliente)
            .then(result => {
                setClient(result.data.Data.Dados[0])
            });
    }, [dados.idCliente, history])

    useEffect(() => { ReactGA.pageview(window.location.pathname + window.location.search) }, [])

    useEffect(() => {
        fetchCart(pedido)
            .then(result => {
                setInvoice(result.data.Data.Dados[0])
                if (result.data.Data.Dados[0].NumeroItens === 0) {
                    window.location.href = process.env.REACT_APP_BASE_URL
                }

                if (result.data.Data.Dados[0].Valor < 5800) {
                    setIsOpenFrete(true)
                }

                if (result.data.Data.Dados[0].idCondicaoPagamento > 0) {
                    setInputs({
                        ...inputs,
                        'idCondicaoPagamento': result.data.Data.Dados[0].idCondicaoPagamento
                    });

                }

                setRatingOldInvoice(pedido)
            })
            .catch(reject => {
            })

    }, [inputs, pedido])

    useEffect(() => {
        fetchPaymentTerms()
            .then(result => {
                let payment = result.data.Data.Dados
                if (payment) {
                    setPaymentTerms(payment)

                    const last = payment.filter(paymentTerm => paymentTerm.UltimaUtilizada === true)

                    setLastPaymentTerm(last[0])
                }
            })
    }, [])

    useEffect(() => {
        fetchDeliveryDate()
            .then(result => {
                setDeliveryDate(result.data.Data.Dados[0])
            })
        /* Deixado somente 1 disconnect */
    }, [])

    const handlePaymentTerm = (e) => {
        e.preventDefault();

        scrollToTop();

        setLoading(true);

        postEndCart()
            .then(resultEndCart => {
                if (resultEndCart.data.Codigo !== 200) {
                    setErrors(resultEndCart.data.Data.Resultados);
                    setLoading(false);
                } else {
                    const novoToken = resultEndCart.data.Data;
                    if (novoToken != null) {
                        setItemSession('_token', novoToken);
                        setItemSession('auth_token', novoToken);
                        fetchCartItems(novoToken).then(newCard => {
                            setItemSession('_carrinho', JSON.stringify(newCard.data.Data));

                        });
                    }
                    const newStorage = {
                        "QuantidadeRegistrosTotal": 0,
                        "QuantidadeRegistrosRetornados": 0,
                        "Paginas": 1,
                        "ResultadosPorPagina": 0,
                        "Dados": []
                    }
                    refreshCartFn(newStorage);

                    setLoading(false);
                    setConfirmScreen(true);

                    if (invoice.RealizarPesquisaSatisfacao) {
                        showModal();
                    }
                }
            })
            .catch(reject => {
                disconnect();
            })
    }

    const onInputChange = event => {
        const { name, value } = event.target;
        if(value === ''){
            sethabilitapedido(false)
        }else{
            sethabilitapedido(true)
            setInputs({
                ...inputs,
                [name]: value
            });
        }

       

        if (event.target.value !== '') {

            postSelectedPaymentTerm(value)
                .then(result => {
                    var pedido = result.data.Data.Pedido;

                    result = result.data.Data.Itens;
                    result = {
                        "QuantidadeRegistrosTotal": result.length,
                        "QuantidadeRegistrosRetornados": result.length,
                        "Paginas": 1,
                        "ResultadosPorPagina": result.length,
                        "Dados": result
                    };
                    setItemSession('_carrinho', JSON.stringify(result));
                    refreshCartFn(result);

                    setInvoice(pedido);
                });

        }


    };

    const handleRate = ({ rating }) => {
        setRating(rating)
    }

    const onTextareaRateChange = event => {
        setRatingObs(event.target.value);
    };

    const handleSubmitRate = (e) => {
        e.preventDefault();
        postSurveyRate(ratingOldInvoice, rating, ratingObs)
            .then(result => {
                hideModal()
            })
            .catch(reject => {
                disconnect();
            })
    }

    return (
        <>
            <section className="container checkout">
                <h2 className="titulo h2">Fechamento do pedido</h2>
                <div className="checkout--container">
                    {!isLoading ? (
                        !confirmScreen &&
                        <div className="checkout--wrapper">
                            {errors?.length > 0 &&
                                <div className="alert alert-danger" role="alert">
                                    <h4 className="alert-heading">Atenção, seu pedido não foi gerado!</h4>
                                    <p>Verifique os erros listados abaixo::</p>
                                    <hr />
                                    <ul>
                                        {errors.map(error => (<li className="mb-0">{error.Mensagem}</li>))}
                                    </ul>
                                    <Link to="/carrinho" className="link-texto">Voltar para o carrinho</Link>
                                </div>
                            }

                            <div className="checkout--campo">
                                <h4 className="titulo h4">Faturamento</h4>
                                <p className="checkout--texto">Faturar para {client.RazaoSocial}</p>
                            </div>

                            <div className="checkout--campo">
                                <h4 className="titulo h4">Pagamento</h4>
                                <form action="">
                                    <fieldset className="form-group">
                                        <label className="checkout--label" htmlFor="select-checkout">Condição de pagamento</label>
                                        <select className="form-control" onChange={onInputChange} required name="idCondicaoPagamento" id="select-checkout">
                                            <option value="">Definir o prazo de pagamento</option>
                                            {paymentTerms?.map((paymentTerm, index) => (
                                                <option
                                                    key={`payment-${index}`}
                                                    value={paymentTerm.idCondicaoPagamento}
                                                    defaultValue={paymentTerm.idCondicaoPagamento === inputs.idCondicaoPagamento}
                                                >{paymentTerm.nParcelas}x ({paymentTerm.Descricao})</option>
                                            ))}
                                        </select>
                                    </fieldset>
                                </form>
                                <p className="checkout--texto checkout--texto-icon">
                                    {lastPaymentTerm &&
                                        <>
                                            <img src="./img/icone-info.svg" alt="" />
                                            <span>Última condição utilizada | <b>{lastPaymentTerm.Descricao}</b></span>
                                        </>
                                    }
                                </p>
                            </div>
                            <div className="checkout--campo">
                                <h4 className="titulo h4">Fechamento</h4>
                                <div className="checkout--container_valor">
                                    <p className="checkout--texto_valor">Valor Total  <span className="checkout--valor">R$ {invoice.Valor}</span></p>
                                    {habilitapedido
                                        ? <button data-toggle="modal" data-target="#modalCheckout" className="btn btn--cinza">Confirmar Pedido</button>
                                        : <button onClick={() => alert("Selecione a Condição de Pagamento")} className="btn btpost End Carn--cinza">Confirmar Pedido</button>
                                    }
                                </div>
                            </div>
                        </div>

                    ) : (
                        <Loader short="false" />
                    )}

                    {confirmScreen &&
                        <div className="checkout--sucesso">
                            <span className="icobutton">
                                <span className="icon icon-check"></span>
                            </span>

                            <h2 className="titulo h2 text-center">Seu pedido foi concluído <br /> com êxito!</h2>

                            <Link to="/pedidos" className="btn btn--lg btn--roxo btn--bold">Ver todos seus pedidos</Link>
                        </div>
                    }
                </div>
                <div className="text-center">
                <Link to="/carrinho" className="link-texto">Voltar para o carrinho</Link>
                </div>
            </section>

            {/* <!-- Modal --> */}
            <Modal animation={false} show={isOpen} className="modal modal-rate fade">
                <Modal.Body>
                    <div className="text-center">
                        <form onSubmit={handleSubmitRate}>
                            <h4>Quão satisfeito você está com a experiência de compra?</h4>
                            <Rater
                                total={5}
                                rating={rating}
                                onRate={handleRate}
                            />
                            <br /><br />
                            Para qualquer observação, preencha o campo abaixo <small>(opcional)</small>:
                            <textarea onChange={onTextareaRateChange} name="obs" className="input textarea" id="obs" rows="3"></textarea>
                            <br />
                            <button type="submit" className="btn btn--cinza btn--full">Enviar</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>



            {/* <!-- Modal --> */}
            {/* <Modal show={isOpenFrete} className="modal modal-rate fade">
             <Modal.Header>
                    <div className="text-center">
                        <h2 className="text-center titulo h2">Política de Envio</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <div className="text-center">
                           <h5 small> </h5> 
                        </div>
                        <Link type="button" className="btn-primary btn-sm btn-modal-home" to="/">Mais opções</Link>{' '}
                        <button type="button" className="btn-warning btn-sm btn-modal-home" onClick={()=> hideModalFrete()}>Continuar</button>
                    </div>
                </Modal.Body>
            </Modal> */}

            <div className="modal modal-checkout fade" id="modalCheckout" tabIndex="-1" role="dialog" aria-labelledby="modalCheckout" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="icon icon-times"></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2 className="titulo h2 text-center">Confirma o pedido?</h2>
                            <p>Deseja finalizar o pedido de número - {invoice?.NumeroPedido ?? ""}?</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={handlePaymentTerm} name="btn-checkout" data-dismiss="modal" aria-label="Close" className="btn btn--lg btn--cinza btn--bold">Sim, finalizar!</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Checkout);