import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import Auth from './pages/Auth';
import Home from './pages/Home';
import Manufacturers from './pages/Manufacturers';
import Product from './pages/Product';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import Unauthorized from './pages/Unauthorized';
import Highlights from './pages/Highlights';
import Account from './pages/Account';
import Cart from './pages/Cart';
import Login from './pages/Login';
import LoginAnonimo from './pages/LoginAnonimo';
import Checkout from './pages/Checkout';
import Invoices from './pages/Invoices';
import InvoiceDetail from './pages/InvoiceDetail';
import PendenciesDetail from './pages/PendenciesDetail';
import Contact from './pages/Contact';
import Search from './pages/Search';
import { getItemSession } from './utils';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga'
import LoginFilial from './pages/LoginFilial';
import Footer from './components/Footer';
import Header from './components/Header';

ReactGA.initialize('UA-199246216-1')

function App() {

  const [name] = useState(window.location.pathname);
  const [, setCookie] = useCookies(['user']);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])



  var urlProduto = name.split('/');
  urlProduto = urlProduto.find(element => element === 'produto')

  if (urlProduto === 'produto') {
    setCookie('LinkProduto', name, { path: '/' });
  }
  const isAuthenticated = getItemSession('_token'); 

  return (
    <BrowserRouter key={`main-browser-router`}>
      <Switch key="switch-main-route">
        <Route key={`auth-route`} exact path="/auth/:token" component={Auth} />
        <Route key={`login-route`} exact path="/login" component={Login} />
        <Route key={`login-anonimo-route`} exact path="/login_vitrine" component={LoginAnonimo} />
        <Route key={`login-anonimo-route`} exact path="/login_filial" component={LoginFilial} />
        <Route key={`not-found-route`} exact path="/not-found" component={NotFound} />
        <Route key={`unauthorized-route`} exact path="/unauthorized" component={Unauthorized} />
        <Route key={`logout-route`} path="/logout" exact component={Logout} />

        <Route exact path="/" render={() => (
          <>
            <Header />
            <Home />
            <Footer />
          </>
        )} />
      {isAuthenticated && (
        <>
            <Header />
            <Route key={`fornecedores-route`} exact path="/fornecedores" component={Manufacturers} />
            <Route key={`destaque-route`} exact path="/destaque/:name" component={Highlights} />
            <Route key={`produto-route`} exact path="/produto/:id" component={Product} />
            <Route key={`busca-route`} exact path="/busca/:term?" component={Search} />
            <Route key={`carrinho-route`} exact path="/carrinho" component={Cart} />
            <Route key={`checkout-route`} exact path="/checkout" component={Checkout} />
            <Route key={`pedidos-route`} exact path="/pedidos" component={Invoices} />
            <Route key={`pedido-route`} exact path="/pedido/:id" component={InvoiceDetail} />
            <Route key={`pendencias-route`} exact path="/pendencias" component={PendenciesDetail} />
            <Route key={`minha-conta-route`} exact path="/minha-conta" component={Account} />
            <Route key={`fale-conosco-route`} exact path="/fale-conosco" component={Contact} />
            <Footer />
            </>
      )}

      <Route key="all_paths-route" path="*">
        <Redirect to="/" />
      </Route>

    </Switch>
  </BrowserRouter>
  );
}

export default App;