import React from 'react';
import xmlbuilder from 'xmlbuilder';
import { FaFileAlt } from 'react-icons/fa';

const generateXML = (invoiceItems) => {
  const root = xmlbuilder.create('Pedido');

  invoiceItems.forEach((invoiceItem, index) => {
    const item = root.ele('Item', { id: index });
    item.ele('Descricao', invoiceItem.Descricao);
    item.ele('NumeroNF', invoiceItem.NumeroNF);
    item.ele('ValorUnitario', invoiceItem.Valor);
    item.ele('CantidadRequerida', invoiceItem.QuantidadePedida);
    item.ele('CantidadFacturada', invoiceItem.QuantidadeFaturada);
    item.ele('ImporteFacturado', invoiceItem.Valor * invoiceItem.QuantidadeFaturada);
  });

  return root.end({ pretty: true });
};

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body]);
  const fileName = filename;
  if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
  } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}

const XMLExporter = ({ invoiceItems }) => {
  const handleXML = () => {
    const xmlData = generateXML(invoiceItems);
    createAndDownloadBlobFile(xmlData, 'pedido.xml');
  };

  return (
    <a onClick={handleXML} className="btn btn--lg btn--branco btn--bold">
        <FaFileAlt />
        <span style={{fontSize: "0.75em", lineHeight: 1.2}} >Download XML</span>
    </a>
  );
};

export default XMLExporter;

