import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import Brands from '../components/SideBar/Brands';
import ProductCard from '../components/Product/ProductCard';
import { Loader } from '../components/Loader/Loader';
import { scrollToTop, arrayStringToArrayInt, clearSubCategorias, getItemSession } from '../utils';
import { fetchProductsSearch } from '../services/products-api';
import { arrOrigin } from '../constants';
import { getCategoryById, getAllCategories, getBrandById } from '../containers/CategoriesContainer';

const Search = () => {
    const [isLoading, setLoading] = useState(true);
    const [qtd, setQtd] = useState(0);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [brandDescriptions, setBrandDescriptions] = useState([]);
    const [categoryDescriptions, setCategoryDescriptions] = useState([]);
    const [subCategoryDescriptions, setSubCategoryDescriptions] = useState([]);
    const [search] = useState(window.location.search)
    const [produtosEmEstoque, setProdutosEmEstoque] = useState(false);
    const login = JSON.parse(getItemSession('_dados'));
    const MyPageNumber = localStorage.getItem('MyPageNumber');
    let params = new URLSearchParams(search);

    const term = params.get('t') || '';
    const fornecedores = params.get('f') || '';
    const categorias = params.get('c') || '';
    let arrCategorias = categorias ? arrayStringToArrayInt(categorias) : [];
    const subCategorias = params.get('s') || '';
    let arrSubCategorias = subCategorias ? arrayStringToArrayInt(subCategorias) : [];
    const brand = params.get('b') || '';
    let arrBrands = brand ? arrayStringToArrayInt(brand) : [];

  
    const filterHierarquia = (idList) => {
        let posArrHierarquia;

        let arr = idList;

        for (let a in arr) {
            posArrHierarquia = getCategoryById(arr[a]);
            arr[a] = posArrHierarquia.Hierarquia
        }

        return arr;
    }

    let arrHierarquia = null;
    let arrClearSubCategorias = null;
    let xArrClearSubCategorias = null;


    if (subCategorias) {
        xArrClearSubCategorias = clearSubCategorias(arrSubCategorias, arrCategorias, getAllCategories(0));

        arrClearSubCategorias = clearSubCategorias(arrSubCategorias, arrCategorias, getAllCategories(0));
        arrHierarquia = filterHierarquia(arrClearSubCategorias);
    }

    if (categorias && (arrHierarquia === null || arrHierarquia.length === 0)) {
        arrHierarquia = filterHierarquia(arrayStringToArrayInt(categorias))
    }


    const fetchEstoque = () => {
        fetchProductsSearch(term === '' ? null : term, fornecedores === '' ? null : fornecedores, categorias === '' ? null : arrHierarquia, brand === '' ? null : 2, arrBrands.length === 0 ? null : arrBrands, MyPageNumber == null ? currentPage : MyPageNumber, recordPerPage)
            .then(result => {
                if (produtosEmEstoque && !login.ApenasVisualizacao) {
                    fetchProductsSearch(term === '' ? null : term, fornecedores === '' ? null : fornecedores, categorias === '' ? null : arrHierarquia, brand === '' ? null : 2, arrBrands.length === 0 ? null : arrBrands, currentPage, recordPerPage)
                        .then(result => {
                            setProducts(result.data.Data.Dados.filter(dado => dado.Estoque > 0))
                            CountItensEstoque();
                        })
                        .then()
                        .catch(reject => {
                        })
                } else {
                    setProducts(result.data.Data.Dados)
                    setQtd(result.data.Data.QuantidadeRegistrosTotal)
                    if (result.data.Data.QuantidadeRegistrosTotal < 20) {
                        handlePageChange(1)
                    }
                    setLoading(false)
                }


            })
            .catch(reject => {
                console.log(reject, 'reject 2')
            })

    }

    async function CountItensEstoque() {
        await fetchProductsSearch(term === '' ? null : term, fornecedores === '' ? null : fornecedores, categorias === '' ? null : arrHierarquia, brand === '' ? null : 2, arrBrands.length === 0 ? null : arrBrands, null, null)
            .then(result => {
                setQtd(result.data.Data.Dados.filter(dado => dado.Estoque > 0).length);
                if (result.data.Data.Dados.filter(dado => dado.Estoque > 0).length < 20) {
                    handlePageChange(1)
                }
            }).then(result => setLoading(false))
            .catch(reject => {
            })
    }



    const handleEstoque = () => {
        setQtd(0)
        setLoading(true)
        setProdutosEmEstoque(!produtosEmEstoque)
    }

    // Paginate
    const totalRecords = qtd;
    const recordPerPage = process.env.REACT_APP_PAGINATION_RECORD_PER_PAGE;
    const pageRange = process.env.REACT_APP_PAGINATION_PAGE_RANGE;

    const handlePageChange = pageNumber => {
        localStorage.setItem('MyPageNumber', pageNumber);
        if (currentPage !== pageNumber) {
            setLoading(true)
            setCurrentPage(pageNumber);
            localStorage.setItem('MyPageNumber', pageNumber);
        }
    }

    // Scroll
    useEffect(() => scrollToTop(), [isLoading]);

    // Load Data
    useEffect(() => {
        if (xArrClearSubCategorias && xArrClearSubCategorias.length > 0 && arrCategorias.length > 0) {
            const subCategoriesDescriptions = xArrClearSubCategorias.map(id => getCategoryById(id).Descricao);
            setSubCategoryDescriptions(subCategoriesDescriptions);
        }
    
        if (arrCategorias.length > 0) {
            const categoriesDescriptions = arrCategorias.map(id => getCategoryById(id).Descricao);
            setCategoryDescriptions(categoriesDescriptions);
        }

        if (arrBrands.length > 0)
        {
            const brandsDescriptions = arrBrands.map(id => getBrandById(id).Descricao);
            setBrandDescriptions(brandsDescriptions);
        }
    
        fetchEstoque();
    }, [currentPage, produtosEmEstoque]);

    return (
        <>
            <section className="conteudo_interno conteudo_padrao container">

                <div className="filtro-sticky d-none d-lg-block">
                    {!login.ApenasVisualizacao ? (
                        <fieldset className="form-group">
                            <input type="checkbox" checked={produtosEmEstoque} onChange={() => handleEstoque()} className="checkbox" id="produtosEmEstoque" />
                            <label htmlFor="produtosEmEstoque" title="Produtos">Produtos disponíveis</label>
                        </fieldset>
                    ) : ''
                    }
                    <Brands />
                </div>
                <div className="cards-container">
                    <div className="titulo-container">
                        <h4 className="titulo h2">
                        {brandDescriptions && brandDescriptions.length > 0 && (
                                <>
                                    <br />
                                    <small className="text-muted subcategoria">Marca(s)</small>{' '}
                                    {brandDescriptions.map((descricao, index) => (
                                        <React.Fragment key={`brand-${index}`}>
                                            <small className="descricao">{descricao}</small>
                                            {index < brandDescriptions.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </>
                            )}

                            {categoryDescriptions && categoryDescriptions.length > 0 && (
                                <>
                                    <br />
                                    <small className="text-muted subcategoria">Categoria(s)</small>{' '}
                                    {categoryDescriptions.map((descricao, index) => (
                                        <React.Fragment key={`cat-${index}`}>
                                            <small className="descricao">{descricao}</small>
                                            {index < categoryDescriptions.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                            {subCategoryDescriptions && subCategoryDescriptions.length > 0 && (
                                <>
                                    <br />
                                    <small className="text-muted subcategoria">Subcategoria(s)</small>{' '}
                                    {subCategoryDescriptions.map((descricao, index) => (
                                        <React.Fragment key={`sub-${index}`}>
                                            <small className="descricao">{descricao}</small>
                                            {index < subCategoryDescriptions.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </>
                            )}


                            {term && `Busca: "${term}"`}


                        </h4>

                        <p className="total-itens">Total de itens: <span className="total-itens_numero">{qtd}</span></p>
                    </div>

                    <nav aria-label="paginacao-promocoes">
                        {!isLoading &&
                            <Pagination
                                hideFirstLastPages
                                activePage={MyPageNumber != null ? parseInt(MyPageNumber) : currentPage}
                                itemsCountPerPage={parseInt(recordPerPage)}
                                totalItemsCount={totalRecords}
                                pageRangeDisplayed={parseInt(pageRange)}
                                onChange={handlePageChange}
                                innerClass="pagination justify-content-end"
                                itemClass="page-item"
                                linkClass="page-link"

                            />
                        }
                    </nav>

                    <div className="cards cards-produto">
                        <div className='row'>
                            {!isLoading ? (
                                products?.length > 0
                                    ? products.map(product =>
                                        <ProductCard
                                            className='col-md-6'
                                            key={`search-card-${product.idProduto}`}
                                            product={product}
                                            origin={arrOrigin['pagina_busca']}
                                        />
                                    )
                                    : 'Nenhum produto encontrado'
                            ) : (
                                <Loader short="false" />
                            )}
                        </div>
                    </div>

                    <nav aria-label="paginacao-promocoes">
                        {!isLoading &&
                            <Pagination
                                hideFirstLastPages
                                activePage={MyPageNumber != null ? parseInt(MyPageNumber) : currentPage}
                                itemsCountPerPage={parseInt(recordPerPage)}
                                totalItemsCount={totalRecords}
                                pageRangeDisplayed={parseInt(pageRange)}
                                onChange={handlePageChange}
                                innerClass="pagination justify-content-end"
                                itemClass="page-item"
                                linkClass="page-link"

                            />
                        }
                    </nav>
                </div>
            </section>
        </>
    )
}

export default Search;