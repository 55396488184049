import React, { useState, useEffect, createElement } from 'react';
import { Link } from 'react-router-dom';
import { getItemSession } from '../../../utils';


const DropdownUser = () => {
    const [nome, setNome] = useState([]);
    const [cnpj, setCnpj] = useState([]);
    const dados = JSON.parse(getItemSession('_dados'));

    useEffect(() => {
        if (dados) {
            setNome(dados.Nome);
            setCnpj(dados.Login);
        }
    }, [dados])

    return (
        <>
            {!dados.ApenasVisualizacao ?
                <div className="dropdown dropdown_user">
                    <button className="btn btn--icon dropdown-toggle" type="button" id="UserDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="dropdown_user_img"><span className="fas fa-user"></span></span>
                        <span className="dropdown_user_msg">
                            <b>{nome}</b>
                            <small>CNPJ {cnpj}</small>
                        </span>
                        <span className="fas fa-chevron-down"></span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="UserDropdown">
                        <div className="dropdown-menu_cont">
                            <ul className="list-unstyled mb-0">
                                <li>
                                    {createElement('a', { href: '/minha-conta', className: 'dropdown-menu_link' }, "Minha Conta")}
                                </li>
                                <li>
                                    {createElement('a', { href: '/pedidos', className: 'dropdown-menu_link' }, "Meus Pedidos")}
                                </li>
                                <li>
                                    {createElement('a', { href: '/pendencias', className: 'dropdown-menu_link' }, "Pendências")}
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown-menu_footer">
                            <Link to="/logout" className="dropdown-menu_link">Encerrar sessão</Link>
                        </div>
                    </div>
                </div>
                :
                <div className="dropdown_user user-hitbox">
                    <Link className="btn btn--icon dropdown-toggle" type="button" id="UserDropdown" to="/logout">
                        <span className="dropdown_user_img"><span className="fas fa-user"></span></span>
                        <span className="dropdown_user_msg">
                           Login
                        </span>
                    </Link>
                </div>
            }
        </>
    );
}

export default DropdownUser;