import React, { useEffect } from 'react';
import { useHistory  } from 'react-router-dom';
import { setItemSession, getItemSession, getCookie } from '../utils';

const Logout = () => {
    const history = useHistory();

    

    useEffect(() => {
        if (window.sessionStorage.length > 0) {
            let filial = getItemSession('_filialSelecionadaCanopus');
            let urlCanoupus =  getCookie('_CanopusBlink');
            if(urlCanoupus !== null){
                filial = 0
            }
            window.sessionStorage.clear();
            window.localStorage.clear();
            switch(filial) {
                case '3':
                    setItemSession('_filialSelecionadaCanopus', 3);
                    window.location.href = process.env.REACT_APP_URL_REDE_SIRIUS;
                    break;
                case '4':
                    setItemSession('_filialSelecionadaCanopus', 4);
                    window.location.href = process.env.REACT_APP_URL_REDE_ATACADO;
                    break;
                default:
                    setItemSession('_filialSelecionadaCanopus', filial);
                    window.location.href = process.env.REACT_APP_URL_REDE_blink;
                    break;
            }
        } else {
            history.push("/");
        }
    }, [history]);

    return (
            <section className="conteudo_interno container">
                <div className="d-flex align-items-center">
                    <strong className="mr-2">Saindo... </strong>
                    <div className="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                </div>
            </section>
    )

}

export default Logout;