import React, { createElement } from 'react';
import {  getItemSession } from '../../../utils';

const MenuPrincipal = () => { 
    const login = JSON.parse(getItemSession('_dados'));
    
    return (
        <div className="header_nav_cont center" id="menuPrincipal">
            <h4 className="titulo h4">Menu principal</h4>
            <nav className="nav">
                <ul className="list-unstyled mb-0">
                    <li className="nav-item">
                        {!login.ApenasVisualizacao ? <a className="nav-link" href="/minha-conta">Minha Conta</a> : ' '}
                    </li>
                    <li className="nav-item">
                        {!login.ApenasVisualizacao && <a className="nav-link" href="/pedidos">Meus Pedidos</a>}
                    </li>
                    <li className="nav-item">
                        {!login.ApenasVisualizacao && <a className="nav-link" href="/pendencias">Pendências</a>}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/busca?t=", className: "nav-link" }, "Categorias")} 
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine1", className: "nav-link" }, "Lançamentos")}
                    </li>               
                    <li className="nav-item">
                        <a role="button" href="/destaque/promocao" className="nav-link">Promoções</a>
                    </li>
                    <li className="nav-item">
                        {!login.ApenasVisualizacao && <a role="button" href="#carrinho" className="nav-link nav-dropdown">Carrinho<span className="fa fa-chevron-right"></span></a> }
                    </li>
                    <br/>
                    <li className="nav-item">
                        {login.ApenasVisualizacao ? <a className="nav-link" href="/logout">Login</a> : <a className="nav-link" href="/login_vitrine">Encerrar sessão</a>}
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default MenuPrincipal;