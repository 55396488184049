import React, { useEffect, useRef, useState } from 'react';
import { fetchLoginVitrine, postDevice } from '../services/login-api';
import { AuthLoader } from '../components/Loader/AuthLoader';
import { fetchFiliais } from '../services/client-api';
import { setItemSession } from '../utils';

import {
    deviceType,
    browserName,
    browserVersion,
    osName,
    osVersion
} from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const LoginAnonimo = (props) => {
    const latestProps = useRef(props);
    
    
    useEffect(() => { latestProps.current = props; });
    useEffect(() => {
        const registrarDispositvo = (browser, so, uniqueKey, serialNumber) => {

            postDevice(browser, so, uniqueKey, serialNumber)
                .then(async result => {
                    var idDevice = result.data.Data.Retorno;
                    Cookies.remove('_register_' + isHomolog);
                    Cookies.set('_register_' + isHomolog, idDevice, { expires: 3650 });
                    doLogin(idDevice)
                })
        }

        const doLogin = async (idDevice) => {
            await fetchLoginVitrine(idDevice)
                .then(result => {
                    if (result.data.Codigo === 200) {
                        const resultData = result.data.Data.Retorno;
                        const client = {
                            idCliente: resultData.idCliente,
                            idUsuario: resultData.idUsuario,
                            Login: resultData.Login,
                            Nome: resultData.Nome,
                            Email: resultData.Email,
                            ukPedido: resultData.ukPedido,
                            DataEmissao: resultData.DataEmissao,
                            NumeroItens: resultData.NumeroItens,
                            PedidoAlterado: resultData.PedidoAlterado,
                            LinkExpiracaoSessao: resultData.LinkExpiracaoSessao,
                            ApenasVisualizacao: resultData.ApenasVisualizacao
                        }

                        var token = resultData.TokenResposta;

                        fetchFiliais(token).then(resultFilial => {
                            const filiais = resultFilial.data.Data;

                            setItemSession('auth_token', latestProps.current.match.params.token);
                            setItemSession('_dados', JSON.stringify(client));
                            setItemSession('_pedido', client.ukPedido);
                            setItemSession('_token', token);

                            if (filiais) {
                                var nFiliais = filiais.Dados.length;
                                setItemSession('_unicaFilial', (nFiliais === 1));
                            }
                            else {
                                setItemSession('_unicaFilial', false);
                            }
                            latestProps.current.history.push('/login_filial')
                        })
                    } else {
                        latestProps.current.history.push('/unauthorized');
                    };
                })
                .catch(error => {
                    latestProps.current.history.push('/unauthorized');
                })
        }

        var ambiente = process.env.REACT_APP_API_EMPRESA;
        var isHomolog = ambiente.toLowerCase().includes("Teste") ? true : false;

        var myCookie = Cookies.get('_register_' + isHomolog);
        let device = myCookie;

        if (!myCookie || device == null || device === 'null' || device === '' || device === 0 || device === undefined || device === '0') {
            const browser = browserName + ' ' + browserVersion + ' ' + deviceType;
            const so = osName + ' ' + osVersion;
            const serialNumber = uuidv4();
            const uniqueKey = uuidv4();

            registrarDispositvo(browser, so, uniqueKey, serialNumber)
        } else {
            doLogin(device);
        }
    }, [])

    return <AuthLoader />;
}

export default LoginAnonimo;