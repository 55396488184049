import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchBanners = (position = null, tag = null, filtrosAdicionais = null) => {
    console.log(position)
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "Banner",
        "Parametros": [
        ]
    }

    if (position) {
        options.Parametros.push({ "Nome": "PosicionamentoBanner", "Valor": position })
    }

    if (tag) {
        options.Parametros.push({ "Nome": "Tag", "Valor": tag })
    }
  
    if (filtrosAdicionais) {
        options.Parametros.push({ "Nome": "FiltrosAdicionais", "Valor": filtrosAdicionais })
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

