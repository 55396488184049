import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchClient = (idCliente) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "Cliente",
        "Parametros": [
            {
                "Nome": "idCliente",
                "Valor": parseInt(idCliente)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);

    })
}


export const fetchSeller = (idCliente) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "Consultor",
        "Parametros": [
            {
                "Nome": "idCliente",
                "Valor": parseInt(idCliente)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchEnderecos = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    
    const options = {
        "Esquema": "web",
        "Tabela": "ClienteEndereco",
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);

    })
}
export const fetchFiliais = (token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "Filial",
        
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)
       
        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);

    })
}


export const fetchMudarFilial = (params) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    
    const options = {
        "idFilial": parseInt(params)
    }
    
    return new Promise(async (resolve, reject) => {
        const ret = http.post(`Dados/AlterarFilial`, options, config)
        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        await resolve(ret);
    })
}

export const fetchMudarFilialToken = (params, token) => {
    const config = {
        headers: { Authorization: `Bearer ${token ?? HTTP_TOKEN}` }
    }
    const options = {
        "idFilial": parseInt(params)
    }

    return new Promise(async (resolve, reject) => {
        const ret = http.post(`Dados/AlterarFilial`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        await resolve(ret);
    })
}

