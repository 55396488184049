import React from 'react';
import Logo from './Logo/LogoResponsive';
import SearchBar from './SearchBar/SearchBarResponsive';
import DropdownCart from './DropdownCart/DropdownCartResponsive';
import MenuPrincipal from '../NavBarResponsive/Menu/MenuPrincipal';


export default function HeaderResponsive() {
    return (
        <div className="header_topo d-lg-none">
            <div>
                <button className="btn btn--icon btn-menu" onClick={MenuPrincipal} >
                    <span></span><span></span><span></span>
                </button>
            </div>
            <Logo />
            <SearchBar />
            <DropdownCart />
        </div>
    )
}