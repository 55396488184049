import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const InvoicePDF = ({ invoiceItems }) => {
  const styles = StyleSheet.create({
    container: {
      margin: 20,
    },
    table: {
      border: 1,
      borderColor: '#000',
      borderWidth: 1,
      padding: 10,
      marginBottom: 20,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 12,
      marginBottom: 5,
    },
    text: {
      fontSize: 10,
      marginBottom: 5,
    },
  });

  return (
    <Document>
      <Page size="A4">
        <View style={styles.container}>
          {invoiceItems.map((invoiceItem, i) => (
            <View key={i} style={styles.table}>
              <Text style={styles.title}>{invoiceItem.Descricao}</Text>
              <Text style={styles.text}>Nº Factura: {invoiceItem.NumeroNF}</Text>
              <Text style={styles.text}>Valor Unitario: $ {invoiceItem.Valor}</Text>
              <Text style={styles.text}>Cantidad Requerida: {invoiceItem.QuantidadePedida}</Text>
              <Text style={styles.text}>Cantidad Facturada: {invoiceItem.QuantidadeFaturada}</Text>
              <Text style={styles.text}>
                Importe Facturado: $ {invoiceItem.Valor * invoiceItem.QuantidadeFaturada}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
