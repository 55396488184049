import styled from 'styled-components';

export const FooterTipBarWrapper = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const TipBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin-top: 20px;
`;

export const TipBarContainerAbout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin-top: 20px;
  align-items: center;
`;

export const TipBarColumn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 768px) {
    flex: 0 0 48%;
    max-width: 48%;
  }

  @media (min-width: 992px) {
    flex: 0 0 23%;
    max-width: 23%;
  }
`;

export const TipBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TipBarIcon = styled.div`
  margin-bottom: 10px;

  svg {
    font-size: 24px; /* Tamanho da fonte para os ícones */
  }
`;

export const TipBarTexts = styled.div`
  text-align: center;

  p {
    margin: 0;
    font-size: 14px; /* Tamanho da fonte para os textos */
  }
`;

export const TipBarTextsAbout = styled.div`
  text-align: center;

  h5 {
    margin-bottom: 10px;
  }

  a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: white;
    font-size: 14px;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
  }

  li {
    svg {
      font-size: 24px;
    }
  }
`;

export const SecuritySection = styled.div`
  strong {
    display: block;
    margin-bottom: 10px;
    font-size: 16px; /* Tamanho da fonte para o título da seção */
  }

  .footer__selos {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      img {
        max-width: 100px;
      }
    }
  }
`;

export const Divider = styled.div`
  width: 87%;
  height: 1px;
  background-color: #E1E1E1; /* ou a cor desejada */
  margin: 20px 0;
`;