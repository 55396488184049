import { getItemSession } from '../utils';

export const getAllManufacturers = () => {
    let manufacturers = getItemSession('_fornecedores');

    return JSON.parse(manufacturers);
}

export const getManufacturerById = (id) => {
    let selected = getAllManufacturers().filter(manufacturer => parseInt(manufacturer.idFornecedor) === parseInt(id));
   
    return JSON.parse(JSON.stringify(selected[0]));
}
