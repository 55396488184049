import React, { useEffect, createElement, useState } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import { setItemSession } from '../utils';

const Unauthorized = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    
    const clearCookies = () => {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    };

    // Limpar localStorage, sessionStorage e cookies quando o componente for montado
    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();
    }, []);

    function hendlerVoltarHome(){     
        setLoading(true);  
        setTimeout(() => {
            history.push("/");
        }, 4000);
    }
    return (
        <>
            <section className="conteudo_interno container">
                <div className="text-center">
                    <h1 className="display-4">Oops!</h1>
                    <p>Desculpe, ocorreu um erro com a ação realizada.</p>
                    <p>Os dados inseridos para o login não coincidem. Entre em contato com seu consultor de franquia ou registre uma solicitação na Central.</p>
                    <div className="btnUnauthorized">
                        {createElement('a', { href: "/logout", className: "mt-5 btn btn--cinza btn--block btn--full btn--bold" }, "Tente novamente")}
                    </div>
                    <div className="btnUnauthorized">
                        <Link to={"/"} className="mt-5 btn btn--cinza btn--block btn--full btn--bold" onClick={hendlerVoltarHome}>
                            {loading && <i className="fa fa-refresh fa-spin ml-2"></i>}Voltar para o portal
                        </Link>                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default Unauthorized;