import { getItemSession } from '../utils';

export const getAllCategories = (anterior) => {
    let categories = getItemSession('_categorias');
    return (categories ===  null || categories === 'undefined') ? [] : JSON.parse(categories).filter(c => !anterior || anterior === null || c.idClassificacaoNivelAnterior === anterior);
}

export const getCategoryById = (id) => {
    let selected = getAllCategories().filter(category => parseInt(category.idClassificacaoNivel) === parseInt(id));
    return JSON.parse(JSON.stringify(selected[0]));
}

export const getAllBrands = () => {
    let brands = getItemSession('_marcas');
    return JSON.parse(brands);
}

export const getBrandById = (id) => {
    let selected = getAllBrands().filter(category => parseInt(category.idClassificacaoNivel) === parseInt(id));
    return JSON.parse(JSON.stringify(selected[0]));
}
