import React, { useEffect, useRef, useState } from 'react';
import { fetchManufacturers } from '../services/manufacturers-api';
import { fetchCategories, fetchCategoriesById } from '../services/categories-api';
import { fetchVersion, fetchHomolog } from '../services/system-api';
import { fetchCartItems } from '../services/cart-api';
import { setItemSession, getItemSession } from '../utils';
import { fetchFiliais, fetchMudarFilialToken } from '../services/client-api';
import { AuthLoader } from '../components/Loader/AuthLoader';
import '../scss/loginFilial.scss';
import { useCookies } from 'react-cookie';
import Spinner from 'react-bootstrap/Spinner'

const LoginFilial = (props) => {
    var token = getItemSession('_token')

    const [spinnerLoad, setSpinnerLoad] = useState(false);
    const [filiais, setFiliais] = useState([]);
    const [filialSelecionada, setFilialSelecionada] = useState('');
    const [urlOriginal, setUrlOriginal] = useState(false);

    const [cookies, setCookie] = useCookies([]);
    const latestProps = useRef(props);

    
    useEffect(() => { latestProps.current = props; });
    useEffect(() => {
        var token = getItemSession('_token')
        fetchFiliais(token).then(result => {
            if (result?.data?.Data?.Dados.length > 0) {
                setFiliais(result.data.Data.Dados);
            }
            var currentURL = window.location.href;

            var domain = currentURL.split('//')[1].split('.')[0];
            switch(domain) {
                case 'siriusdistribuidora':
                    setCookie('_filialSelecionadaCanopus', 3, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });
                    loadData(3)
                    setItemSession('_unicaFilial', true);
                    break;
                case 'atacadocanopus':
                    setCookie('_filialSelecionadaCanopus', 4, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });
                    loadData(4)
                    setItemSession('_unicaFilial', true);
                    break;
                default:
                    setCookie('_CanopusBlink', 4, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });
                    loadData(4)
                    setItemSession('_unicaFilial', true);
                    break;
            }
        })
    }, [])

    useEffect(() => {
        let filialSelecionadaLocal = cookies._filialSelecionadaCanopus;

      
        


        const loginAutomatico = () => {
            if (cookies._filialSelecionadaCanopus) {
                filialSelecionadaLocal.toString();
                if (filialSelecionadaLocal) {
                    loadData(filialSelecionadaLocal);
                }
            }
        }
        if (filialSelecionadaLocal) {          
            loginAutomatico();
        }
    }, [filialSelecionada])


    const filialSelected = async (idFilial) => {
        loadData(idFilial);
        setFilialSelecionada(idFilial);
    }

    const loadData = (idFilial) => {
        setSpinnerLoad(true)
        var token = getItemSession('_token')
        fetchMudarFilialToken(idFilial, token).then(r => {
            setItemSession('_filialSelecionadaCanopus', idFilial);

            const novoToken = r.data.Data;
            if (novoToken != null) {
                token = novoToken;
                setItemSession('_token', token);
            }

            Promise.all([
                fetchManufacturers(token),
                fetchCategories(token),
                fetchVersion(token),
                fetchHomolog(token),
                fetchCartItems(token),
                fetchCategoriesById(token, 5, false, true)
            ])
                .then(resultFetch => {
                    setItemSession('_fornecedores', JSON.stringify(resultFetch[0].data.Data.Dados));
                    setItemSession('_categorias', JSON.stringify(resultFetch[1].data.Data.Dados))
                    setItemSession('blink_versao', JSON.stringify(resultFetch[2].data.Data))
                    setItemSession('blink_homolog', JSON.stringify(resultFetch[3].data.Data))
                    setItemSession('_carrinho', JSON.stringify(resultFetch[4].data.Data))
                    setItemSession('_marcas', JSON.stringify(resultFetch[5].data.Data.Dados))
                    setSpinnerLoad(false)

                    setCookie('_filialSelecionadaCanopus', idFilial, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });
                    window.location.href = process.env.REACT_APP_BASE_URL
                })
        })
    }
    if(urlOriginal){
        return (
            <>
                <section className='section-login-filial'>
                    <div className='painelLogo-nome' />
    
                    <div className='painel-login-filial'>
                        <div className='btn-filial'>
                            {filiais?.map((filial, index) => (
                                <button className='btn-painel-filial'
                                    onClick={() => filialSelected(filial.idFilial)}
                                >{filial.Descricao}</button>
                            ))}
                        </div>
                    </div>
                    <div className="spinner-center">
                        {/* <label htmlFor="exampleInputEmail1">Usuário</label> */}
    
                        {spinnerLoad &&
                            <Spinner animation="border" />
                        }
                    </div>
                </section>
            </>
        );
    }else{
        return <AuthLoader />;
    }
    

    
}

export default LoginFilial;